.page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 100vh;
	font-family: 'Roboto flex', sans-serif;
	font-variation-settings: "wdth" 151, "xtra" 603;
	letter-spacing: 1px;
	line-height: 1.6;
}
.text-header {
	font-size: 52px;
}
.text-outline {
	-webkit-text-stroke: 2px #222;
	color: transparent;
}
body {
	--background-color: #f3f2f9;
}
body[theme="dark"]{
	--background-color: #2b2b33;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	background-color: var(--background-color);
	color: #222222;
}

button {
	cursor: pointer;
}

.expand .menu-page{
	width: 100%;
	height: 100%;
	transform: scale(1);
	border-radius: 0;
	opacity: 1;
}
.collapse .menu-page{
	width: 0;
	height: 0;
	transform-origin: top right;
	overflow: hidden;
	border-bottom-left-radius: 50%;
	opacity: 0;
}
.menu-page {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 99999;
	font-family: "Roboto Flex", sans-serif;
	font-size: 52px;
	background: #f0f0f0;
	display: flex;
	align-items: center;
	padding-left: 15%;
	/* transition: opacity 0.5s ease-in-out, width 0.2s ease-in-out, height 0.2s ease-in-out, border-radius 0.2s ease-in-out; */
	/* transition: all 0.2s ease-in-out; */
}
.menu-page ol{
	max-width: 100%;
	padding: 24px;
	width: fit-content;
	/* margin: auto; */
}
.menu-page ol li{
	list-style: none;
	counter-increment: counter;
	transition: all 0.2s ease-in-out;
	font-variation-settings: "wght" 1000, "wdth" 151, "XTRA" 603;
	cursor: pointer;
}
.menu-page ol li:hover{
	font-variation-settings: "wght" 900, "wdth" 151, "XTRA" 503;
}
.menu-page ol li::before{
	content: "0" counter(counter);
	font-size: 16px;
	margin-right: 8px;
	font-variation-settings: "wght" 1000, "wdth" 151, "XTRA" 603;
}

svg {
	cursor: pointer;
}
svg, svg * {
	transition: all 0.2s ease-in-out;
}
svg:hover .circle {
	opacity: 0;
}
svg:hover .rect1, .menu-close .rect1 {
	transform: translateX(-12px) translateY(0);
	width: 30px;
}
svg:hover .rect2, .menu-close .rect2 {
	transform: translateX(0px) translateY(-12px);
	height: 30px;
}
svg:hover .circle1, .menu-close .circle1 {
	transform: translate(-20px, -20px);
}
svg:hover .circle2, .menu-close .circle2 {
	transform: translate(0, -20px);
}
svg:hover .circle3, .menu-close .circle3 {
	transform: translate(20px, -20px);
}
svg:hover .circle4, .menu-close .circle4 {
	transform: translate(20px, 0);
}
svg:hover .circle5, .menu-close .circle5 {
	transform: translate(20px, 20px);
}
svg:hover .circle6, .menu-close .circle6 {
	transform: translate(0, 20px);
}
svg:hover .circle7, .menu-close .circle7 {
	transform: translate(-20px, 20px);
}
svg:hover .circle8, .menu-close .circle8 {
	transform: translate(-20px, 0);
}
svg.menu-close {
	transform: rotate(45deg);
}

nav {
	position: fixed;
	top: 96px;
	width: 100%;
	pointer-events: none;
	display: flex;
	justify-content: space-between;
	padding: 0 96px;
	z-index: 999999;
}
.allow-cursor{
	pointer-events: auto;
}